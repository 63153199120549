<template>
  <el-container class="set-rule-wrap bg-f p-16 flex-d-c">
    <div class="header">
      <h3>
        {{ $route.query.name }}--{{
          $route.query.mark == "new" ? "新建规则" : "编辑规则"
        }}
      </h3>
    </div>
    <el-form
      class="com-form"
      ref="formRef"
      :rules="formRules"
      :model="formModel"
      label-width="110px"
      size="small"
    >
      <h5>基础设置</h5>
      <el-form-item label="名称：" prop="name">
        <el-input
          class="name-ipt"
          v-model="formModel.name"
          placeholder="请输入规则名称-不超过15个字符"
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item label="执行时间：" prop="execute_time">
        <el-radio-group v-model="formModel.execute_time">
          <el-radio label="fix">
            <el-dropdown
              size="mini"
              placement="bottom-start"
              @command="
                command => {
                  formModel.schedule_type = command;
                }
              "
            >
              <span class="every-day mr-10">{{ formModel.schedule_type }}</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in dailTimes"
                  :key="index"
                  :command="item.value"
                  >{{ item.value }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <span>检查一次</span>
          </el-radio>
          <el-popover
            popper-class="set-time-pop f-14 pt-0"
            title="指定规则运行的时间和频度"
            placement="bottom"
            width="600"
            trigger="click"
          >
            <set-time-frequency
              :initData="cusTimeModel"
              :dailTimes="dailTimes"
              :schedule_customs="schedule_customs"
              ref="postTime"
              @post-time_frequency="
                (str, obj) => {
                  time_frequency = str;
                  cusTimeModel = { ...obj };
                }
              "
            >
            </set-time-frequency>
            <button
              @click.prevent="setTime"
              style="background-color: transparent;border: none;"
              slot="reference"
            >
            <el-radio label="cus">指定时间 </el-radio>
            </button>
          </el-popover>
          
        </el-radio-group>
        <el-popover
          v-if="formModel.execute_time == 'cus'"
          trigger="hover"
          placement="top"
          :content="time_frequency"
        >
          <i
            slot="reference"
            v-if="formModel.execute_time == 'cus'"
            class="el-icon-date color-theme f-16 ml-10"
          ></i> 
        </el-popover>
      </el-form-item>
      <h5>规则设置</h5>
      <el-form-item label="如果：" prop="entity_type">
        <el-select
          filterable
          placeholder="请选择类型"
          v-model="formModel.entity_type"
          @change="handleChangeEntityType"
        >
          <el-option
            v-for="(item, index) in entity_types"
            :key="index"
            :label="`${item.VALUE}（${item.KEY}）`"
            :value="item.KEY"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 条件 -->
      <el-form-item v-for="(item, index) in cusConditions" :key="index">
        <p>{{ index == 0 ? "当" : "并且必须符合以下条件" }}</p>
        <div class="dp-flex condition-item">
          <el-form-item>
            <el-select
              filterable
              placeholder="条件"
              v-model="item.field"
              @change="val => handleChangeCondition(val, { index })"
            >
              <el-option
                v-for="(con, coni) in conditions"
                :key="coni"
                :label="con.memo"
                :value="con.name"
                :disabled="con.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              filterable
              placeholder="操作"
              v-model="cusConditions[index].operator"
              @change="$forceUpdate()"
            >
              <el-option
                v-for="(opt, opti) in item.operators"
                :key="opti"
                :label="opt.VALUE"
                :value="opt.KEY"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :prop="`value${index}`"
            :rules="{
              required: true,
              message: '值必填！',
              trigger: ['blur']
            }"
          >
            <el-input
              @input="$forceUpdate()"
              type="number"
              v-if="item.type == 'INTEGER'"
              class="normal-ipt"
              v-model.number="formModel[`value${index}`]"
              placeholder="整数值"
              clearable
            >
              <template v-if="item.unit" slot="append">{{
                item.unit
              }}</template>
            </el-input>
            <el-input
              @input="$forceUpdate()"
              v-else
              class="normal-ipt"
              v-model="formModel[`value${index}`]"
              placeholder="输入值"
              clearable
            >
              <template v-if="item.unit" slot="append">{{
                item.unit
              }}</template>
            </el-input>
          </el-form-item>
          <!-- 不/在范围内 -->
          <template
            v-if="
              cusConditions[index].operator == 'IN_RANGE' ||
                cusConditions[index].operator == 'NOT_IN_RANGE'
            "
          >
            <span class="mr-10">_ </span>
            <el-form-item
              :prop="`valueRange${index}`"
              :rules="{
                validator: (rule, value, callback) =>
                  handleValidateRange(rule, value, callback, {
                    operator: item.operator
                  }),
                trigger: ['change', 'blur']
              }"
            >
              <el-input
                @input="$forceUpdate()"
                type="number"
                v-if="item.type == 'INTEGER'"
                class="normal-ipt"
                v-model.number="formModel[`valueRange${index}`]"
                placeholder="整数值"
                clearable
              >
                <template v-if="item.unit" slot="append">{{
                  item.unit
                }}</template>
              </el-input>
              <el-input
                @input="$forceUpdate()"
                v-else
                class="normal-ipt"
                v-model="formModel[`valueRange${index}`]"
                placeholder="输入值"
                clearable
              >
                <template v-if="item.unit" slot="append">{{
                  item.unit
                }}</template>
              </el-input>
            </el-form-item>
          </template>
          <i
            @click="handleDelCondition(index)"
            v-if="index > 0"
            class="el-icon-close color-theme cursor-pointer pr"
            style="top:9px;"
          ></i>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="handleAddConditon"
          type="text"
          icon="el-icon-circle-plus-outline"
          >添加条件</el-button
        >
      </el-form-item>
      <!-- execute -->
      <el-form-item label="那么：">
        <div class="dp-flex condition-item">
          <el-form-item prop="execute">
            <el-select
              filterable
              placeholder="执行方式"
              v-model="formModel.execute"
              @change="handleChangeExecutions"
            >
              <el-option
                v-for="(item, index) in executes"
                :key="index"
                :label="item.memo"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <template v-if="executeItem.extra">
            <el-form-item prop="amount">
              <el-input
                clearable
                placeholder="请输入"
                type="number"
                v-model.number="executeItem.struct.amount"
              >
                <el-select
                  class="amount-sel"
                  slot="append"
                  v-model="executeItem.struct.unit"
                >
                  <el-option
                    v-for="(unit, uniti) in executeItem.extra.unit"
                    :key="uniti"
                    :label="unit.VALUE"
                    :value="unit.KEY"
                  ></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item prop="limit">
              <el-input
                clearable
                placeholder="请输入"
                type="number"
                v-model.number="executeItem.struct.limit"
              >
                <template slot="prepend">{{
                  executeItem.name.indexOf("INCREASE_") != -1
                    ? `最大${executeItem.memo.slice(0, 2)}到`
                    : `最小${executeItem.memo.slice(0, 2)}到`
                }}</template>
                <template slot="append">{{
                  executeItem.extra.limit_unit[0].VALUE
                }}</template>
              </el-input>
            </el-form-item>
          </template>
        </div>
      </el-form-item>
      <!-- 邮箱 -->
      <el-form-item>
        <div class="dp-flex">
          <el-checkbox v-model="formModel.is_send_msg"
            >发送通知到：
          </el-checkbox>
          <el-form-item prop="email">
            <el-input
              clearable
              v-if="showEmailIpt || !formModel.email"
              placeholder="请输入邮箱"
              v-model="formModel.email"
            >
            </el-input>
            <span v-else class="color-theme">{{ formModel.email }}</span>
          </el-form-item>
          <el-button
            class="align-self-start mx-10"
            type="text"
            @click="showEmailIpt = !showEmailIpt"
            >编辑</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="检查范围：" prop="time_preset">
        检查
        <el-dropdown
          size="mini"
          placement="bottom-start"
          @command="
            command => {
              formModel.time_preset = command;
            }
          "
        >
          <span class="every-day mr-10">{{ formModel.time_preset }}</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in time_presets"
              :key="index"
              :command="item.VALUE"
              >{{ item.VALUE }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        的数据
      </el-form-item>
      <el-form-item class="confirm-item">
        <el-button @click="handleRulePreview" type="primary" icon="fa fa-save"
          >保存设置</el-button
        >
        <el-button
          type="primary"
          icon="fa fa-reply-all"
          @click="$router.push({ name: 'AutoRules' })"
          >退出</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 规则预览 -->
    <rule-preview-dialog
      :visible="rulePreviewVisible"
      @close-dialog="rulePreviewVisible = false"
      :data="rulePreviewData"
      :initModel="initPreviewModel"
      @confirm-set-rule="handleConfirmSetRule"
    ></rule-preview-dialog>
    <!-- comMethodsLy -->
    <com-methods-ly ref="comMethodsLyRef"></com-methods-ly>
  </el-container>
</template>

<script>
import "font-awesome/css/font-awesome.min.css";
import { tableHeight, fnCSTToYmd } from "@/utils/utils.js";
import setTimeFrequency from "@/views/autoRules/components/setTimeFrequency";
import rulePreviewDialog from "@/views/autoRules/components/rulePreviewDialog";
import comMethodsLy from "@/views/components/comMethodsLy";
import {
  getruledicts,
  getUsers,
  addrule,
  getRuleById,
  updaterule
} from "@/api/autoRules";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    setTimeFrequency,
    rulePreviewDialog,
    comMethodsLy
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleDicts: {},
      time_presets: [],
      total: "",
      formModel: {
        name: "",
        execute_time: "fix",
        entity_type: "ADSET",
        execute: "PAUSE",
        is_send_msg: true,
        schedule_type: "每天",
        time_preset: "最近三天（含当天）",
        email: "",
        value0: "1000",
        valueRange0: ""
      },
      cusTimeModel: {},
      formRules: {
        name: [
          {
            required: true,
            message: "请输入策略名称！",
            trigger: ["change", "blur"]
          },
          {
            min: 1,
            max: 15,
            message: "不超过15个字符",
            trigger: ["change", "blur"]
          }
        ],
        execute_time: {
          required: true,
          message: "请选择执行时间！",
          trigger: ["change", "blur"]
        },
        level: {
          required: true,
          message: "请选择类型！",
          trigger: ["change", "blur"]
        },
        email: {
          required: true,
          message: "请输入邮箱！",
          trigger: ["change", "blur"]
        }
      },
      entity_types: [],
      // 条件items
      cusConditions: [
        {
          field: "impressions",
          operator: "GREATER_THAN",
          type: "INTEGER",
          unit: "",
          // value: "1000",
          // valueRange: "",
          operators: []
        }
      ],
      conditions: [],
      executes: [],
      executeItem: {},
      userInfo: {},
      dailTimes: [
        { key: "SEMI_HOURLY", value: "每半小时" },
        { key: "HOURLY", value: "每小时" },
        { key: "DAILY", value: "每天" }
      ],
      schedule_customs: [
        { value: "MONDAY", name: "周一" },
        { value: "TUESDAY", name: "周二" },
        { value: "WEDNESDAY", name: "周三" },
        { value: "THURSDAY", name: "周四" },
        { value: "FRIDAY", name: "周五" },
        { value: "SATURDAY", name: "周六" },
        { value: "SUNDAY", name: "周日" }
      ],
      // 指定时间
      time_frequency: "",
      showEmailIpt: false,
      // 规则预览
      rulePreviewData: {},
      rulePreviewVisible: false,
      initPreviewModel: {},
      // 编辑规则-初始回显信息
      ruleInitInfo: {},
      usdArr: [
        "bid_amount",
        "spent",
        "cost_per_unique_click",
        "cpc",
        "cpm",
        "cpp",
        "cost_per_mobile_app_install",
        "daily_budget",
        "lifetime_budget",
        "cost_per",
        "cost_per_purchase_fb"
      ],
      perArr: [
        "ctr",
        "daily_ratio_spent",
        "lifetime_ratio_spent",
        "cpa",
        "yesterday_spent / adset.daily_budget"
      ],
      // 编辑规则时接口返回的页面初始数据
      editOriginData: {}
    };
  },
  computed: {
    ...mapState("num", ["activeAdAccount"]),
    ...mapGetters("num", ["currentUiUnit"]),
    // 币种转换基数
    convertBase() {
      let uiUnit = this.activeAdAccount.currency.uiUnit,
        units = this.activeAdAccount.currency.units,
        obj = units.find(item => item.uiDefault == uiUnit.uiDefault),
        base = 1;
      if (obj) {
        base = obj.multiToBase;
      }
      return base;
    }
  },
  methods: {
    handleValidateRange(rule, value, callback, { operator }) {
      if (operator == "IN_RANGE" || operator == "NOT_IN_RANGE") {
        if (!value) {
          callback(new Error("值必填！"));
        } else {
          callback();
        }
      }
    },
    addEditSuccessFn(tip) {
      this.rulePreviewVisible = false;
      let num = 5;
      // <p>${num}秒后自动关闭</p>
      this.$msgbox({
        dangerouslyUseHTMLString: true,
        title: "",
        message: `<p><i class="el-icon-circle-check color-success"></i><span>规则${tip}成功</span></p>`,
        showCancelButton: true,
        confirmButtonText: `继续${tip}`,
        cancelButtonText: "返回规则管理"
      })
        .then(success => {})
        .catch(error => {
          this.$router.push({ name: "AutoRules" });
        });
    },
    updaterule(obj) {
      this.$showLoading();
      updaterule(this.setRuleParamsFn(obj)).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          this.addEditSuccessFn("编辑");
        }
      });
    },
    getRuleById(adRuleId) {
      this.$showLoading();
      getRuleById({ adRuleId }).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          if (res.data) {
            this.editOriginData = { ...res.data };

            this.editInitPageFn(JSON.parse(JSON.stringify(res.data)));
          }
        }
      });
    },
    async editInitPageFn(data) {
      let res = await getruledicts();
      if (res.code == 200) {
        this.ruleDicts = res.data;
        this.createEntityTypesFn(res.data);
        // time_presents
        this.time_presets = res.data.conditions.find(
          d => d.name == "time_preset"
        ).extra;
      }
      this.formModel.email = data.emails ? data.emails : this.userInfo.email;
      this.formModel.name = data.name;
      // 执行时间-回显
      // 固定-fix
      let schedule_type =
          data.conditions.find(item => item.field == "schedule_type") &&
          data.conditions.find(item => item.field == "schedule_type").value,
        schedule_custom =
          data.conditions.find(item => item.field == "schedule_custom") &&
          data.conditions.find(item => item.field == "schedule_custom").value,
        momentObj = data.conditions.find(
          item => item.field == "schedule_start_minute"
        ),
        moment = "";
      if (schedule_type != "CUSTOM") {
        this.formModel.execute_time = "fix";
        this.formModel.schedule_type = this.dailTimes.find(
          item => item.key == schedule_type
        ).value;
      } else {
        // 自定义-cus
        this.formModel.execute_time = "cus";
        if (momentObj) {
          let a = Math.floor(momentObj.value / 60),
            h = a < 10 ? "0" + a : a,
            s = momentObj.value % 60 ? momentObj.value % 60 : "00";
          moment = `${h}:${s}`;
          this.cusTimeModel.schedule_type = "每天";
        } else {
          this.cusTimeModel.schedule_type = "";
        }
        this.cusTimeModel = {
          startTime: data.startTime ? data.startTime : "",
          endTime: data.endTime ? data.endTime : "",
          schedule_custom: schedule_custom.length
            ? schedule_custom.map(
                item => this.schedule_customs.find(d => d.value == item).name
              )
            : [],
          moment
        };
      }

      // entity_type-回显
      let entity_type = data.conditions.find(
        item => item.field == "entity_type"
      ).value;
      this.formModel.entity_type = entity_type;

      this.handleChangeEntityType(entity_type);

      // execute-回显
      let execute = data.executions[0];
      this.formModel.execute = execute.field;

      this.handleChangeExecutions(execute.field);
      if (execute.struct.amount) {
        let keys = ["amount", "unit", "limit", "limit_unit"];
        keys.forEach(k => {
          this.executeItem.struct[k] = execute.struct[k];
        });
      }
      //  time_preset-回显
      let time_preset = data.conditions.find(
        item => item.field == "time_preset"
      ).value;
      this.formModel.time_preset = this.time_presets.find(
        item => item.KEY == time_preset
      )
        ? this.time_presets.find(item => item.KEY == time_preset).VALUE
        : "最近三天（含当天）";
      // other-conditons-回显
      let fixCons = [
        "entity_type",
        "time_preset",
        "schedule_custom",
        "schedule_type",
        "schedule_start_minute"
      ];
      let cusConditions = data.conditions.filter(item =>
        fixCons.every(c => c != item.field)
      );
      if (cusConditions) {
        cusConditions.forEach((item, index) => {
          item.operators =
            this.ruleDicts &&
            this.ruleDicts.conditions &&
            this.ruleDicts.conditions.find(c => c.name == item.field).operators;
          // formModel
          let val1 = typeof item.value != "object" ? item.value : item.value[0],
            val2 = typeof item.value == "object" ? item.value[1] : "";
          this.$set(
            this.formModel,
            `value${index}`,
            this.convertedVal(item.field, val1)
          );
          this.$set(
            this.formModel,
            `valueRange${index}`,
            this.convertedVal(item.field, val2)
          );
          item.unit = this.createUnitFn(item.field);
          this.$set(this.cusConditions, index, { ...item });
        });
      }

      // times-frequency--回显
      let previewModel = {};
      if (execute.struct.times) {
        previewModel.type = 1;
      } else if (execute.struct.frequency) {
        previewModel.type = 2;
        previewModel.num = execute.struct.frequency / 3600;
      } else {
        previewModel.type = 0;
        previewModel.num = "";
      }
      previewModel.status = Boolean(data.status);
      this.initPreviewModel = previewModel;
    },
    setRuleParamsFn(obj) {
      // executions-struct
      let struct = {};
      if (obj.type == 1) {
        struct.times = 1;
      } else if (obj.type == 2) {
        struct.frequency = obj.num ? obj.num * 60 * 60 : "";
      }
      if (this.executeItem.extra) {
        let arr = Object.keys(this.executeItem.struct);
        arr.forEach(p => {
          struct[p] = this.executeItem.struct[p];
        });
      }
      // otherConArr-execute_time
      let otherConArr = [],
        cusObj = {},
        _val = "";
      if (this.formModel.execute_time == "fix") {
        _val = this.dailTimes.find(
          item => item.value == this.formModel.schedule_type
        ).key;
      } else {
        _val = "CUSTOM";
      }
      cusObj = {
        field: "schedule_type",
        type: "ENUM_STRING",
        operator: "EQUAL",
        value: _val
      };
      otherConArr.push(cusObj);
      if (this.formModel.execute_time == "cus") {
        if (this.cusTimeModel.moment) {
          let arr = this.cusTimeModel.moment.split(":"),
            moment = Number(arr[0]) * 60 + Number(arr[1]),
            _obj = {
              field: "schedule_start_minute",
              type: "INTEGER",
              operator: "EQUAL",
              value: moment
            };
          otherConArr.push(_obj);
        }
      }

      // otherConArr-conditon
      this.cusConditions.forEach((item, i) => {
        let _item = JSON.parse(JSON.stringify(item));
        delete _item.operators;
        if (_item.operator != "IN_RANGE" && _item.operator != "NOT_IN_RANGE") {
          _item.value = this.unconvertVal(
            item.field,
            this.formModel[`value${i}`]
          );
        } else {
          _item.value = [
            this.unconvertVal(item.field, this.formModel[`value${i}`]),
            this.unconvertVal(item.field, this.formModel[`valueRange${i}`])
          ];
        }
        delete _item.unit;
        otherConArr.push(_item);
      });

      // schedule_custom
      let schedule_custom = [];
      if (
        this.cusTimeModel.schedule_custom &&
        this.cusTimeModel.schedule_custom.length
      ) {
        schedule_custom = this.cusTimeModel.schedule_custom.map(
          item => this.schedule_customs.find(c => c.name == item).value
        );
      }

      let addEditObj = {};
      if (this.$route.query.mark == "new") {
        addEditObj = {
          strategyId: this.$route.query.id,
          adAccountId: this.$store.getters.fbAccountId
        };
      } else {
        addEditObj = {
          adRuleId: this.$route.query.id
        };
      }

      let params = {
        ...addEditObj,
        status: obj.status ? 1 : 0,
        emails: this.formModel.email,
        startTime: this.cusTimeModel.startTime
          ? this.cusTimeModel.startTime
          : fnCSTToYmd(new Date(), { tag: 1 }),
        endTime: this.cusTimeModel.endTime
          ? this.cusTimeModel.endTime
          : fnCSTToYmd(new Date(), { tag: 1 }),
        content: {
          name: this.formModel.name,
          conditions: [
            {
              field: "entity_type",
              type: "ENUM_STRING",
              operator: "EQUAL",
              value: this.formModel.entity_type
            },
            {
              field: "time_preset",
              type: "ENUM_STRING",
              operator: "EQUAL",
              value: this.time_presets.find(
                t => t.VALUE == this.formModel.time_preset
              ).KEY
            },
            {
              field: "schedule_custom",
              type: "ARRAY_STRING",
              operator: "EQUAL",
              value: schedule_custom
            },
            ...otherConArr
          ],
          executions: [
            {
              field: this.formModel.execute,
              struct
            }
          ]
        }
      };
      return params;
    },
    addrule(obj) {
      this.$showLoading();
      addrule(this.setRuleParamsFn(obj)).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          this.addEditSuccessFn("创建");
          this.rulePreviewVisible = false;
        }
      });
    },
    handleConfirmSetRule(obj) {
      if (this.$route.query.mark == "new") {
        this.addrule(obj);
      } else {
        this.updaterule(obj);
      }
    },
    handleDelCondition(index) {
      this.cusConditions.splice(index, 1);
      delete this.formModel[`value${index}`];
      delete this.formModel[`valueRange${index}`];
    },
    handleAddConditon() {
      let obj = {
        field: "",
        operator: "",
        type: "",
        operators: []
      };
      this.cusConditions.push(obj);
    },
    getUsers() {
      this.$showLoading();
      return getUsers().then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          this.userInfo = res.data;
          return res;
        }
      });
    },
    handleChangeExecutions(val) {
      let obj = this.executes.find(e => e.name == val);
      if (obj && obj["extra"]) {
        obj["struct"] = {
          amount: "",
          unit: obj.extra.unit[0].KEY,
          limit: "",
          limit_unit: obj.extra.limit_unit[0].KEY
        };
      }
      this.executeItem = { ...obj };
    },
    initExecutesFn(data, val) {
      let arr = data.filter(d => d.levels.some(level => level.KEY == val));
      this.executes = [...arr];
      this.formModel.execute = arr[0] ? arr[0].name : "PAUSE";
      this.executeItem = { ...arr[0] };
    },
    initConditonsFn(data, val) {
      let arr = data.filter(d => d.levels.some(level => level.KEY == val));
      let arr1 = arr.filter(item => item.visible);
      this.conditions = [...arr1];
      // 条件相关数据
      let operators = data.find(d => d.name == "impressions")
        ? data.find(d => d.name == "impressions").operators
        : [];
      this.cusConditions = [
        {
          field: "impressions",
          operator: "GREATER_THAN",
          type: "INTEGER",
          unit: "",
          // value: "1000",
          operators
        }
      ];
    },
    // 实体类型(entity_types)改变执行方式(executes)与条件(conditions)随之改变
    handleChangeEntityType(val) {
      let dicts = JSON.parse(JSON.stringify(this.ruleDicts));
      // executes
      this.initExecutesFn(dicts.executions, val);
      // conditions
      this.initConditonsFn(dicts.conditions, val);
    },
    createUnitFn(val) {
      let unit = "",
        isusd = this.usdArr.some(u => u == val),
        ispercent = this.perArr.some(p => p == val);
      if (isusd) {
        unit = this.currentUiUnit.symbol;
      } else if (ispercent) {
        unit = "%";
      } else {
        unit = "";
      }
      return unit;
    },
    assignValue(key, index, arr) {
      let data = { ...this.editOriginData };
      let obj = data.conditions
        ? data.conditions.find(item => item.field == key)
        : null;
      let val1 = "",
        val2 = "",
        operator = "";
      if (obj) {
        val1 = typeof obj.value != "object" ? obj.value : obj.value[0];
        val2 = typeof obj.value == "object" ? obj.value[1] : "";
        // 编辑时操作符初始值
        operator = obj.operator;
      } else {
        operator = arr[0].KEY;
      }
      this.cusConditions[index].operator = operator;
      this.$set(this.formModel, `value${index}`, this.convertedVal(key, val1));
      this.$set(
        this.formModel,
        `valueRange${index}`,
        this.convertedVal(key, val2)
      );
    },
    // 转换基数
    convertedVal(key, val) {
      let isusd = this.usdArr.includes(key);
      if (isusd) {
        val = val ? val / this.convertBase : "";
      }
      return val;
    },
    unconvertVal(key, val) {
      let isusd = this.usdArr.includes(key);
      if (isusd) {
        val = val ? val * this.convertBase : "";
      }
      return val;
    },
    // 条件改娈与之相关的操作也随之改变
    handleChangeCondition(val, { index }) {
      let dicts = JSON.parse(JSON.stringify(this.ruleDicts));
      let arr = dicts.conditions.find(c => c.name == val).operators,
        _type = dicts.conditions.find(c => c.name == val).type;
      this.cusConditions[index] = {
        field: val,
        operator: "",
        type: _type,
        unit: this.createUnitFn(val),
        operators: [...arr]
      };
      this.assignValue(val, index, arr);
      this.$forceUpdate();
    },
    createEntityTypesFn(data) {
      // entity_types-下拉数据
      let oldTypes = [];
      data.executions.forEach(e => {
        oldTypes.push(...e.levels);
      });
      this.entity_types = this.$refs.comMethodsLyRef.FnRemoveDuplicate(
        oldTypes,
        "KEY"
      );
    },
    initPageFn(data) {
      this.createEntityTypesFn(data);
      // execute-默认
      this.initExecutesFn(data.executions, "ADSET");
      // conditions-默认
      this.initConditonsFn(data.conditions, "ADSET");
      // time_presents
      this.time_presets = data.conditions.find(d => d.name == "time_preset")
        ? data.conditions.find(d => d.name == "time_preset").extra
        : [];
      this.formModel.email = this.userInfo.email;
    },
    getruledicts() {
      this.$showLoading();
      return getruledicts().then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          this.ruleDicts = res.data;
          this.initPageFn(res.data);
        }
      });
    },
    createTipRuleFn() {
      let tip = "",
        conStr = "";
      // tip
      if (this.formModel.execute_time == "fix") {
        tip = `系统会在${this.formModel.schedule_type}检查一次${this.formModel.time_preset}的广告系列数据`;
      } else {
        tip = `系统会在${this.time_frequency}${this.formModel.time_preset}的广告系列数据`;
      }
      // conStr
      let conStrArr = [];
      this.cusConditions.forEach((c, ci) => {
        let wobj = this.conditions.find(item => item.name == c.field),
          obj = {
            field: wobj.memo,
            operator: wobj.operators.find(item => item.KEY == c.operator).VALUE,
            value:
              c.operator != "IN_RANGE" && c.operator != "NOT_IN_RANGE"
                ? this.formModel[`value${ci}`]
                : [
                    this.formModel[`value${ci}`],
                    this.formModel[`valueRange${ci}`]
                  ]
          };
        conStrArr.push(obj);
      });
      // entity_type
      let entity_type = this.entity_types.find(
        item => item.KEY == this.formModel.entity_type
      ).VALUE;
      conStrArr.forEach((s, i) => {
        let com = "";
        if (s.operator != "在范围内" && s.operator != "不在范围内") {
          com = `${s.field}${s.operator}${s.value}`;
        } else {
          com = `${s.field}${s.operator}${s.value[0]}-${s.value[1]}`;
        }
        if (i == 0) {
          conStr += `如果${entity_type}的${com}`;
        } else if (i >= 1) {
          conStr += `，并且符合${com}`;
        }
        //  else {
        //   conStr += `，${com}`;
        // }
      });
      conStr += "，那么系统将发通知到你的邮箱";
      this.rulePreviewData = {
        name: this.formModel.name,
        tip,
        conStr
      };
    },
    // 规则预览
    handleRulePreview() {
      // tip-<!-- 系统会在每天的02:00检查一次最近三天（含当天）的广告系列数据 -->
      // conStr- <!-- 如果广告组的展示人数小于100，并且符合点击量小于100，那么系统将发通知到你的邮箱 -->
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.createTipRuleFn();
          this.rulePreviewVisible = true;
        } else {
          return false;
        }
      });
    },
    setTime(v){
      this.formModel.execute_time = 'cus';
      this.$refs['postTime'].emitFormModel();
    }
  },
  async mounted() {
    await this.getUsers();
    // 编辑规则时-回显
    if (this.$route.query.mark == "edit") {
      this.getRuleById(this.$route.query.id);
    } else {
      this.getruledicts();
    }
  },
  watch: {
    cusConditions: {
      handler(val) {
        // 禁选项
        let fields = val.map(item => item.field);
        this.conditions.forEach(item => this.$set(item, "disabled", false));
        //只有一项时没有禁选项
        if (fields && fields.length > 1) {
          fields.forEach(f => {
            let index = this.conditions.findIndex(c => c.name == f);
            if (index != -1) {
              this.$set(this.conditions[index], "disabled", true);
            }
          });
        }
      },
      deep: true
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/commonly";
.set-rule-wrap {
  margin: 0 16px;
  .header {
    h3 {
      font-size: 18px;
      color: rgb(116, 116, 116);
      padding: 15px 0;
      border-bottom: 1px solid rgb(238, 238, 238);
    }
  }
  h5 {
    color: rgb(157, 160, 163);
    padding: 10px 0;
    font-size: 14px;
  }
  .name-ipt {
    width: 510px;
    input {
      width: 100%;
    }
  }
  .normal-ipt {
    input {
      width: 150px;
    }
  }
  .every-day {
    padding: 5px 7px;
    border-bottom: 1px solid $theme;
  }
  .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled)
    .el-radio__inner {
    box-shadow: unset;
  }
  .condition-item {
    .el-form-item {
      @extend .mr-10;
    }
  }
  .amount-sel {
    input {
      width: 100px;
    }
  }
  .confirm-item {
    i {
      margin-right: 5px;
    }
  }
}
.set-time-pop {
  color: $theme-color;
  .el-popover__title {
    @extend .f-14;
    color: $theme-color;
    padding: 5px 16px;
    font-weight: 500;
    border-bottom: 1px solid #f0f0f0;
  }
}
</style>